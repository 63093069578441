import * as React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Metadata from "../components/Metadata";

const IndexPage = () => {
  return (
    <div className="container">
      <Metadata title="Books" description="Books shelves" />

      <Header />

      <div id="gr_grid_widget_1611509191">
        {/* Show static html as a placeholder in case js is not enabled - javascript include will override this if things work */}

        <h2>
          <a
            style={{ textDecoration: "none" }}
            rel="nofollow"
            href="https://www.goodreads.com/review/list/94448181-vaishak-kaippanchery?shelf=currently-reading&utm_medium=api&utm_source=grid_widget"
          >
            Books I am reading now
          </a>
        </h2>

        <div className="gr_grid_container">
          <div className="gr_grid_book_container">
            <a
              title="Thinking, Fast and Slow"
              rel="nofollow"
              href="https://www.goodreads.com/book/show/14062004-thinking-fast-and-slow"
            >
              <img
                alt="Thinking, Fast and Slow"
                border="0"
                src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1337593393l/14062004._SX98_.jpg"
              />
            </a>
          </div>
          <div class="gr_grid_book_container">
            <a
              title="Atomic Habits: An Easy and Proven Way to Build Good Habits and Break Bad Ones"
              rel="nofollow"
              href="https://www.goodreads.com/book/show/54615491-atomic-habits"
            >
              <img
                alt="Atomic Habits: An Easy and Proven Way to Build Good Habits and Break Bad Ones"
                border="0"
                src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1595278069l/54615491._SX98_.jpg"
              />
            </a>
          </div>
        </div>

        <br style={{ clear: "both" }} />
        <br />

        <h2>
          <a
            style={{ textDecoration: "none" }}
            rel="nofollow"
            href="https://www.goodreads.com/review/list/94448181-vaishak-kaippanchery?shelf=read&utm_medium=api&utm_source=grid_widget"
          >
            Books I have read
          </a>
        </h2>
        <div className="gr_grid_container">
          <div class="gr_grid_book_container">
            <a
              title="Angels & Demons (Robert Langdon #1)"
              rel="nofollow"
              href="https://www.goodreads.com/book/show/53430903-angels-demons"
            >
              <img
                alt="Angels & Demons"
                border="0"
                src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1589749537l/53430903._SX98_.jpg"
              />
            </a>
          </div>
          <div class="gr_grid_book_container">
            <a
              title="The Oath of the Vayuputras (Shiva Trilogy, #3)"
              rel="nofollow"
              href="https://www.goodreads.com/book/show/12820793-the-oath-of-the-vayuputras"
            >
              <img
                alt="The Oath of the Vayuputras"
                border="0"
                src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1357242702l/12820793._SX98_.jpg"
              />
            </a>
          </div>
          <div className="gr_grid_book_container">
            <a
              title="The Subtle Art of Not Giving a F*ck: A Counterintuitive Approach to Living a Good Life"
              rel="nofollow"
              href="https://www.goodreads.com/book/show/29965800-the-subtle-art-of-not-giving-a-f-ck"
            >
              <img
                alt="The Subtle Art of Not Giving a F*ck: A Counterintuitive Approach to Living a Good Life"
                border="0"
                src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1480903131l/29965800._SX98_.jpg"
              />
            </a>
          </div>
          <div class="gr_grid_book_container">
            <a
              title="The Secret of the Nagas (Shiva Trilogy #2)"
              rel="nofollow"
              href="https://www.goodreads.com/book/show/40948565-the-secret-of-the-nagas"
            >
              <img
                alt="The Secret of the Nagas"
                border="0"
                src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1532560274l/40948565._SX98_.jpg"
              />
            </a>
          </div>
          <div className="gr_grid_book_container">
            <a
              title="Man's Search for Meaning"
              rel="nofollow"
              href="https://www.goodreads.com/book/show/17304997-man-s-search-for-meaning"
            >
              <img
                alt="Man's Search for Meaning"
                border="0"
                src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1359890258l/17304997._SX98_.jpg"
              />
            </a>
          </div>
          <div className="gr_grid_book_container">
            <a
              title="Siddhartha"
              rel="nofollow"
              href="https://www.goodreads.com/book/show/18710271-siddhartha"
            >
              <img
                alt="Siddhartha"
                border="0"
                src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1411575140l/18710271._SX98_.jpg"
              />
            </a>
          </div>
          <div className="gr_grid_book_container">
            <a
              title="Sapiens: A Brief History of Humankind"
              rel="nofollow"
              href="https://www.goodreads.com/book/show/23346740-sapiens"
            >
              <img
                alt="Sapiens: A Brief History of Humankind"
                border="0"
                src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1427068429l/23346740._SX98_.jpg"
              />
            </a>
          </div>
          <div className="gr_grid_book_container">
            <a
              title="Animal Farm"
              rel="nofollow"
              href="https://www.goodreads.com/book/show/36510264-animal-farm"
            >
              <img
                alt="Animal Farm"
                border="0"
                src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1509467917l/36510264._SX98_.jpg"
              />
            </a>
          </div>
          <div className="gr_grid_book_container">
            <a
              title="Norwegian Wood"
              rel="nofollow"
              href="https://www.goodreads.com/book/show/17308035-norwegian-wood"
            >
              <img
                alt="Norwegian Wood"
                border="0"
                src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1389107313l/17308035._SX98_.jpg"
              />
            </a>
          </div>
          <div className="gr_grid_book_container">
            <a
              title="The Kite Runner"
              rel="nofollow"
              href="https://www.goodreads.com/book/show/12532265-the-kite-runner"
            >
              <img
                alt="The Kite Runner"
                border="0"
                src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1327976246l/12532265._SX98_.jpg"
              />
            </a>
          </div>
          <div className="gr_grid_book_container">
            <a
              title="1984"
              rel="nofollow"
              href="https://www.goodreads.com/book/show/25233603-1984"
            >
              <img
                alt="1984"
                border="0"
                src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1427517454l/25233603._SX98_.jpg"
              />
            </a>
          </div>
          <div className="gr_grid_book_container">
            <a
              title="The Alchemist"
              rel="nofollow"
              href="https://www.goodreads.com/book/show/1175481.The_Alchemist"
            >
              <img
                alt="The Alchemist"
                border="0"
                src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1445444894l/1175481._SX98_.jpg"
              />
            </a>
          </div>
          <div className="gr_grid_book_container">
            <a
              title="The Da Vinci Code"
              rel="nofollow"
              href="https://www.goodreads.com/book/show/4248.The_Da_Vinci_Code"
            >
              <img
                alt="The Da Vinci Code"
                border="0"
                src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1361293878l/4248._SX98_.jpg"
              />
            </a>
          </div>
          <div className="gr_grid_book_container">
            <a
              title="Inferno (Robert Langdon, #4)"
              rel="nofollow"
              href="https://www.goodreads.com/book/show/17212231-inferno"
            >
              <img
                alt="Inferno"
                border="0"
                src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1534070896l/17212231._SX98_.jpg"
              />
            </a>
          </div>
          <div className="gr_grid_book_container">
            <a
              title="The Immortals of Meluha (Shiva Trilogy, #1)"
              rel="nofollow"
              href="https://www.goodreads.com/book/show/7913305-the-immortals-of-meluha"
            >
              <img
                alt="The Immortals of Meluha"
                border="0"
                src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1334659192l/7913305._SX98_.jpg"
              />
            </a>
          </div>
          <div className="gr_grid_book_container">
            <a
              title="The Monk Who Sold His Ferrari: A Fable About Fulfilling Your Dreams & Reaching Your Destiny"
              rel="nofollow"
              href="https://www.goodreads.com/book/show/19367924-the-monk-who-sold-his-ferrari"
            >
              <img
                alt="The Monk Who Sold His Ferrari: A Fable About Fulfilling Your Dreams & Reaching Your Destiny"
                border="0"
                src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1386729501l/19367924._SX98_.jpg"
              />
            </a>
          </div>
          <br style={{ clear: "both" }} />
          <br />
          <a
            className="gr_grid_branding"
            style={{
              fontSize: ".9em",
              color: "#33cc66",
              textDecoration: "none",
              float: "right",
              clear: "both",
            }}
            rel="nofollow"
            href="https://www.goodreads.com/user/show/94448181-vaishak-kaippanchery"
          >
            My favorite books »
          </a>
          <noscript>
            <br />
            Share{" "}
            <a rel="nofollow" href="/">
              book reviews
            </a>{" "}
            and ratings with Vaishak, and even join a{" "}
            <a rel="nofollow" href="/group">
              book club
            </a>{" "}
            on Goodreads.
          </noscript>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default IndexPage;
